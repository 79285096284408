import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KinterestedObject } from './fullstory.service';
import { SessionStorageService } from './session-storage.service';

// Parts of this code inspired / taken from https://github.com/mzuccaroli/angular-google-tag-manager/
// This service is to interact with, start up, and otherwise manage the relationship we have with Google Tag Manager.
// Please reach out to the Marketing team for any questions regarding anything within Google Tag Manager.

@Injectable({
  providedIn: 'root',
})
export class TagManagerService implements OnDestroy {
  storageSubscription: Subscription;

  private loaded = false;

  private config = { id: environment.gtmId || null };
  /* eslint-disable */
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };
  /* eslint-enable */

  constructor(private sessionStorageService: SessionStorageService) {}

  ngOnDestroy() {
    this.storageSubscription.unsubscribe();
  }

  public initialize() {
    if (this.loaded || this.config.id === null || !environment.production) {
      return;
    }

    this.storageSubscription = this.sessionStorageService.sessionStorageSubscription.subscribe(
      (data) => {
        if (data === 'user') {
          this.sendItemsToDataLayer(this.sessionStorageService.retrieve('user'));
        }
      }
    );

    const doc = this.browserGlobals.documentRef();
    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const gtmScript = doc.createElement('script');
    gtmScript.id = 'gtm';
    gtmScript.async = true;
    gtmScript.src = this.applyGtmQueryParams('https://www.googletagmanager.com/gtm.js');
    doc.head.insertBefore(gtmScript, doc.head.firstChild);

    this.loaded = true;
  }

  public getDataLayer() {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private sendItemsToDataLayer(data: string) {
    const info: KinterestedObject = JSON.parse(data);
    if (info) {
      this.pushOnDataLayer({
        address: info.address,
        first_name: info.first_name,
        last_name: info.last_name,
        city: info.city,
        kin_id: info.kin_id,
        product: info.product,
        state: info.state,
        zip: info.zip,
        unit_number: info.unit_number,
        emailAsSHA: info.emailAsSHA,
        email: info.email,
      });
    }
  }

  private pushOnDataLayer(obj: Record<string, unknown>) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }

  private applyGtmQueryParams(url: string) {
    let updatedUrl = url;
    if (updatedUrl.indexOf('?') === -1) {
      updatedUrl += '?';
    }

    return (
      updatedUrl +
      Object.keys(this.config)
        .filter((k) => this.config[k])
        .map((k) => `${k}=${this.config[k]}`)
        .join('&')
    );
  }
}
