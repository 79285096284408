/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import SplitIO from '@splitsoftware/splitio/types/splitio';
import { BehaviorSubject } from 'rxjs';
import { TsKCookies } from '../../../utils/cookie-helper';
import { CreateSplitIOClient } from '../utils/create-split-io-client';

export type SplitClientStatus = null | 'ready' | 'failed' | 'loading';

interface SplitBootstrapConfig {
  splitIoClientKey: string | undefined;
  userIdentifier: string;
}

/**
 * Angular Service to provide SplitIO information to the Angular Application
 * - see readme.md for setup instructions
 * - meant to be used as a singleton, simply inject where needed to get access to treatments
 */
@Injectable({
  providedIn: 'root',
})
export class SplitIOService {
  /** a reference to the split.io client */
  private _clientRef: SplitIO.IClient | null = null;

  /** expose observable stream, do not expose directly to prevent other consumers from emitting it */
  private _clientStatus$ = new BehaviorSubject<SplitClientStatus>(null);

  public clientStatus$ = this._clientStatus$.asObservable();

  /**
   * exposes the split.io client reference
   * API: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK
   */
  public get clientRef(): SplitIO.IClient | null {
    return this._clientRef;
  }

  /**
   * Get Treatment
   * @returns the treatment from split.io
   */
  public getTreatment(splitName: string): string | undefined {
    if (this._clientRef) {
      return this._clientRef.getTreatment(splitName);
    }
    console.warn('No treatments because split client is not defined!');
    return undefined;
  }

  /**
   * Bootstraps the split.io client, required to be called before service can be used.
   * Subscribe to isClientReady$ to be notified when client is ready.
   */
  public bootstrapClient({ splitIoClientKey, userIdentifier }: SplitBootstrapConfig) {
    if (!this._clientRef) {
      this.setClientAsLoading();
      CreateSplitIOClient.createClient({
        splitIoClientKey,
        userIdentifier: TsKCookies.getOrCreateSplitIOCookie(userIdentifier),
      }).then((client) => {
        if (client) {
          this.setClient(client);
        } else {
          this.setClientAsFailed();
        }
      });
    } else {
      console.warn('bootstrapClient:: the split.io client is already initialized');
    }
  }

  /**
   * Sends an event to Split.io; see: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#track.
   *
   * The SplitIO client's `track` method will return `true` if the event was tracked successfully, and `false` if the current queue size
   * is equal to the config set by `eventsQueueSize` or if an incorrect input to the track method has been provided--SplitIO will not tell us what the error is;
   * see the constraints around values provided to `track` here: https://help.split.io/hc/en-us/articles/360020585772-Events#using-the-sdk-track-method.
   *
   * @param trafficType - the type of traffic this event is associated with, e.g. 'user', 'anonymous', etc; this value *must* match
   * the traffic type defined in the Split.io dashboard for the feature flag. See: https://help.split.io/hc/en-us/articles/360019916311-Traffic-type
   * @param eventType - the name of the event, e.g. 'click', 'impression', etc; when tracking events related to feature flags,
   * we can supply a feature-flag-related value here, e.g. 'ar-quoting-single_quote_identifier__treatment_on'. A list of events in the Split.io dashboard
   * can be viewed here: https://app.split.io/org/cbc88ea0-8647-11ea-bae8-0ed25467b33f/ws/cbd16840-8647-11ea-bae8-0ed25467b33f/admin/event-types
   * @param value - the value of the event, e.g. 1, 2, 3, etc; this is optional and we will send 0 by default.
   * @param properties - an object containing additional information about the event, e.g. { 'key': 'value' };
   * @returns void
   */
  public trackEvent(
    trafficType: string,
    eventType: string,
    value: number = 0,
    properties: SplitIO.Properties = {}
  ) {
    // Every event sent to SplitIO will have the `event_source` property set to 'ng-kin'.
    // eslint-disable-next-line no-param-reassign
    properties.event_source = 'ng-kin';

    if (this._clientRef) {
      const result = this._clientRef.track(trafficType, eventType, value, properties);
      if (!result) {
        console.warn('trackEvent:: something went wrong. The event was not tracked');
      }
    } else {
      console.warn('trackEvent:: split.io client is not defined. The event was not tracked');
    }
  }

  /// //////////////////////
  // ADVANCED USE METHODS
  /// //////////////////////
  /**
   * Allows the split.io client to be bootstrapped outside the service and passed in.
   * !!! this should only be done once
   * @param client
   */
  public setClient(client: SplitIO.IClient) {
    if (client && !this._clientRef) {
      this._clientRef = client;
      this.setClientAsReady();
    } else {
      console.warn('setClient:: the split.io client is already initialized');
    }
  }

  /** clientStatus$ Actions: trigger a new event on the clientStatus$ observable stream */
  /** Set Client As Ready */
  public setClientAsReady() {
    this._clientStatus$.next('ready');
  }

  /** Set Client As Failed */
  public setClientAsFailed() {
    this._clientStatus$.next('failed');
  }

  /** Set Client As Loading */
  public setClientAsLoading() {
    this._clientStatus$.next('loading');
  }

  /** Reset Client to Null */
  public resetClientStatus() {
    this._clientStatus$.next(null);
  }
}
