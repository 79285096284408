import { Injectable } from '@angular/core';
import * as Fullstory from '@fullstory/browser';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SessionStorageService } from './session-storage.service';
import { RequestService } from './request.service';
import { GuidService } from './guid.service';

// This service is to interact with Fullstory (https://fullstory.com), our session tracking / recording partner.
// Please ask VP of Product or an Engineering Manager for login credentials.
// These credentials might also be in the company password manager (1Password and/or Rippling's RPass)
// Fullstory API Docs: https://developer.fullstory.com/introduction

export interface KinterestedObject {
  address?: string;
  age?: number;
  birthday?: string;
  brite_verify_status?: string;
  city?: string;
  county?: string;
  county_fips?: string;
  created_at?: number;
  crmId?: number;
  crm_account_id?: number;
  email?: string;
  emailAsSHA?: string;
  first_name?: string;
  id?: string;
  kin_id?: string;
  kintranet_url?: string;
  last_name?: string;
  locale?: string;
  name?: string;
  phone?: string;
  product?: string; // "ho3", "mh3", etc.
  property_id?: number;
  sign_in_count?: number;
  state?: string;
  uuid?: string;
  unit_number?: string;
  userId?: number;
  zip?: string;
}
@Injectable()
export class FullstoryService {
  private config: { [key: string]: string } = {};

  private loaded = false;

  private userSubscription: Subscription;

  private routerSubscription: Subscription;

  private readonly fs = Fullstory;

  constructor(
    private sessionStorageService: SessionStorageService,
    private requestService: RequestService,
    private router: Router,
    private guidService: GuidService
  ) {}

  public initialize() {
    if (this.loaded) {
      return;
    }

    const fsReady = '_fs_ready';

    // We don't want Fullstory on anything but production
    this.fs.init({
      orgId: environment.fullstoryId,
      devMode: false,
    });

    this.loaded = true;

    this.routerSubscription = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        if (JSON.parse(this.sessionStorageService.retrieve('user_exists'))) {
          this.userSubscription = this.requestService
            .get(`${environment.baseUrl + environment.apiPrefix}/users`, true)
            .subscribe((data: KinterestedObject) => {
              if (data.kin_id !== null) {
                this.sendDataToFullStory(data);
                this.userSubscription.unsubscribe();
              }
            });
        }
      }
    });

    // Wait til FullStory is ready, then start sending information about session.
    window[fsReady] = () => {
      this.sessionStorageService.save('fs_url', Fullstory.getCurrentSessionURL());
    };
  }

  public sendABTestEvent(experiment: string, treatment: string) {
    this.fs.event('ab_test_seen', {
      experiment_name_str: experiment,
      treatment_name_str: treatment,
    });
  }

  private sendDataToFullStory(data) {
    this.fs.identify(data.kin_id, {
      displayName: data?.name,
      email: data?.email,
    });

    this.fs.setUserVars({
      uuid: this.guidService.guid,
      address: data?.address,
      state: data?.state,
      product: data?.product,
      property_id: data?.property_id,
      crm_account_id: data?.crm_account_id,
    });
  }

  private setFullstoryVariable(variable: { [key: string]: string }) {
    this.fs.setUserVars(variable);
  }
}
